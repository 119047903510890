import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import RehypeReact from 'rehype-react';
import GatsbyImage from 'gatsby-image';
import MicrositeLayout from '../components/layout/MicrositeLayout';
import useChapters from '../components/how-success-is-written/useChapters';
import HowSuccessIsWrittenFooter from '../components/how-success-is-written/HowSuccessIsWrittenFooter';
import HowSuccessIsWrittenNav from '../components/how-success-is-written/HowSuccessIsWrittenNav';
import arrowLeft from '../images/guides/how-success-is-written/arrow-left.svg';
import arrowRight from '../images/guides/how-success-is-written/arrow-right.svg';
import cover from '../images/guides/how-success-is-written/cover.jpg';
import og from '../images/guides/how-success-is-written/og.jpg';
import linkedinIcon from '../images/guides/how-success-is-written/linkedin.svg';
import twitterIcon from '../images/guides/how-success-is-written/twitter.svg';
import { Chapter, Interview } from '../types';
import Quote from '../components/ui/Quote';
import styles from './HowSuccessIsWrittenTemplate.module.scss';
import Meta from '../components/layout/Meta';

interface HowSuccessIsWrittenPostProps {
  pageContext: {
    slug: string;
  };
}

const BASE_URL = '/guides/how-success-is-written';

const getRenderer = (slabComponentProps: object) => {
  return new RehypeReact({
    createElement: (
      component: React.ElementType,
      props: object,
      children: React.ElementType,
    ) =>
      React.createElement(
        component,
        typeof component === 'string'
          ? props
          : { ...slabComponentProps, ...props },
        children,
      ),
    components: {
      'slab-quote': Quote,
    },
  }).Compiler;
};

const author = (interview: Interview | undefined) => {
  const authorCard = (
    <div className={styles.author}>
      <div>
        {interview?.authorAvatar.childImageSharp ? (
          <GatsbyImage
            fluid={interview?.authorAvatar.childImageSharp.fluid}
            className={styles.authorAvatar}
          />
        ) : null}
      </div>
      <div>
        <p className={styles.authorName}>{interview?.authorName}</p>
        <p className={styles.authorTitle}>{interview?.authorTitle}</p>
      </div>
    </div>
  );

  if (interview?.authorLinkedin || interview?.authorTwitter) {
    return (
      <a
        href={
          interview?.authorPreferredSocial === 'twitter' ||
          !interview?.authorPreferredSocial ||
          !interview?.authorLinkedin
            ? interview?.authorTwitter
            : interview?.authorLinkedin
        }
        target="_blank"
        rel="noreferrer"
      >
        {authorCard}
      </a>
    );
  }

  return authorCard;
};

const HowSuccessIsWrittenTemplate: React.FunctionComponent<
  HowSuccessIsWrittenPostProps
> = ({ pageContext }) => {
  const { slug } = pageContext;

  const chapters: Chapter[] = useChapters();

  const chapter: Chapter | undefined = chapters.find((c) =>
    c.interviews.find((i) => i.slug === slug),
  );

  const interview: Interview | undefined = chapter?.interviews.find(
    (i) => i.slug === slug,
  );

  const interviews = chapters.flatMap((c) => c.interviews);

  const interviewIndex = interviews.findIndex(
    (i) => i.slug === interview?.slug,
  );

  const prevInterview = interviews[interviewIndex - 1]
    ? interviews[interviewIndex - 1]
    : null;

  const nextInterview = interviews[interviewIndex + 1]
    ? interviews[interviewIndex + 1]
    : null;

  const quoteProps = interview?.citations
    ? {
        citations: interview?.citations,
      }
    : {
        name: interview?.authorName,
        title: interview?.authorTitle,
        link: interview?.authorLinkedin
          ? interview.authorLinkedin
          : interview?.authorTwitter,
        picture: interview?.authorAvatar,
      };

  return (
    <MicrositeLayout>
      <Meta
        title={`${interview?.company || ''}: ${interview?.title || ''} - Slab`}
        description={interview?.summary}
        openGraphType="article"
        image={og}
      />
      <HowSuccessIsWrittenNav />
      <div className={styles.grid}>
        <div className={styles.breadcrumbs}>
          <span>
            <Link to={BASE_URL}>How Success is Written</Link>
          </span>
          <span className={styles.breadcrumbArrow} />
          <span>
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            <Link to={`${BASE_URL}/${chapter?.interviews[0].slug}`}>
              Chapter {chapter?.chapterNumber}
            </Link>
          </span>
          <span className={styles.breadcrumbArrow} />
          <span>{interview?.title}</span>
        </div>

        <img
          className={styles.cover}
          src={cover}
          alt="How Success is Written eBook"
        />

        <div className={styles.intro}>
          <div className={styles.metadata}>
            <p className={styles.companyName}>{interview?.company}</p>
            <p className={styles.timeToRead}>{interview?.readtime}</p>
            <div className={styles.social}>
              {interview?.authorLinkedin && (
                <a
                  href={interview?.authorLinkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinIcon} alt="linkedin" />
                </a>
              )}
              {interview?.authorTwitter && (
                <a
                  href={interview?.authorTwitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitterIcon} alt="twitter" />
                </a>
              )}
            </div>
          </div>
          <h1 className={styles.title}>{interview?.title}</h1>
          {author(interview)}
        </div>
        <div className={styles.divider} />

        <div className={styles.chapterNav}>
          {chapters.map((navChapter) => (
            <div key={navChapter.slug}>
              <div
                className={classNames(styles.chapterLink, {
                  [styles.activeChapterLink]: navChapter.slug === chapter?.slug,
                })}
              >
                <Link to={`${BASE_URL}/${navChapter.interviews[0].slug}`}>
                  <div className={styles.chapterNum}>
                    Chapter {navChapter.chapterNumber}
                  </div>
                  <div className={styles.chapterTitle}>{navChapter.title}</div>
                </Link>
              </div>
              <div className={styles.interviews}>
                {navChapter.interviews.map((navInterview) => (
                  <div
                    key={navInterview.slug}
                    className={classNames(styles.interviewLink, {
                      [styles.activeInterviewLink]:
                        navInterview.slug === interview?.slug,
                    })}
                  >
                    <Link to={`${BASE_URL}/${navInterview.slug}`}>
                      <div className={styles.interviewLinkCompany}>
                        {navInterview.company}
                      </div>
                      <div className={styles.interviewTitle}>
                        {navInterview.title}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.chapterContent}>
          {getRenderer(quoteProps)(interview?.htmlAst)}
        </div>
        <div className={styles.bottomNav}>
          {prevInterview ? (
            <Link
              to={`${BASE_URL}/${prevInterview.slug}`}
              className={styles.previousLink}
            >
              <img
                src={arrowLeft}
                alt="Previous"
                className={styles.arrowLeft}
              />{' '}
              Previous
            </Link>
          ) : (
            <Link to={BASE_URL} className={styles.previousLink}>
              <img
                src={arrowLeft}
                alt="Go back to all chapters"
                className={styles.arrowLeft}
              />{' '}
              All chapters
            </Link>
          )}

          {nextInterview ? (
            <Link
              to={`${BASE_URL}/${nextInterview.slug}`}
              className={styles.nextLink}
            >
              {nextInterview.title}{' '}
              <img src={arrowRight} alt="Next" className={styles.arrowRight} />
            </Link>
          ) : null}
        </div>
      </div>

      <HowSuccessIsWrittenFooter />
    </MicrositeLayout>
  );
};

export default HowSuccessIsWrittenTemplate;
